import { render, staticRenderFns } from "./SymbolsSvg.vue?vue&type=template&id=104d5b71&"
var script = {}


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./SymbolsSvg.vue?vue&type=custom&index=0&blockType=svg&width=19&height=17&viewBox=0%200%2019%2017&fill=none&xmlns=http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg"
if (typeof block0 === 'function') block0(component)

export default component.exports