import { Component } from 'shimmer'

import { Renderer } from './Renderer'

import {
  Scene,
  Vector3,
  Vector2,
  AxesHelper,
  PMREMGenerator,
  CubeTextureLoader
} from 'three'

import { PerspectiveCamera } from '@/webGL/cameras'
import { EXRLoader } from 'three/examples/jsm/loaders/EXRLoader.js'

class WebGL extends Component {
  constructor () {
    super()

    this.resize = this.resize.bind(this)
    this.loadBackground = this.loadBackground.bind(this)

    this.scene = new Scene()

    this.camera = new PerspectiveCamera(50, 1, 1, 1000)
    this.camera.far = 300
    this.camera.near = .1
    this.camera.lookAt( new Vector3(0, 0, 0) )

    this.renderer = new Renderer(this.scene, this.camera)
    this.start()
  }

  update () {
    this.camera.update()
    this.renderer.update()
  }

  start () {
    // this.resize()
  }

  loadBackground () {
    // const pmremGenerator = new PMREMGenerator(this.renderer.renderer)
    // pmremGenerator.compileEquirectangularShader()

    // new EXRLoader().load( '/images/textures/background--03.exr', texture => {
    //   const exrCubeRenderTarget = pmremGenerator.fromEquirectangular( texture )
    //   this.scene.background = exrCubeRenderTarget.texture
    //   texture.dispose()
    // })

    return new Promise( (resolve, reject) => {
      this.backgroundTexture = new CubeTextureLoader().load([
          'images/cubemaps/sky/px.jpg',
          'images/cubemaps/sky/nx.jpg',
          'images/cubemaps/sky/py.jpg',
          'images/cubemaps/sky/ny.jpg',
          'images/cubemaps/sky/pz.jpg',
          'images/cubemaps/sky/nz.jpg'
      ])
      // this.backgroundTexture.mapping = CubeReflectionMapping

      this.scene.background = this.backgroundTexture

      resolve()
    })
  }

  set postProcess (value) {
    this.renderer.usePostprocess = value
  }

  resize (args = {}) {
    args.width = args.width !== void 0 ? args.width : window.innerWidth
    args.height = args.height !== void 0 ? args.height : window.innerHeight
    this.renderer.resize(args)
  }

  onDestroy () {
    this.destroy()
    this.renderer.onDestroy()
  }

  set el (el) {
    this.container = el
    this.container.appendChild(this.renderer.canvas)
  }
}

export const webGL = new WebGL()