<template>
  <div class="u-wrapper-panel">
    <transition-group name="t-fader">
      <keypoint
        :to="getParams(index)"
        :position="keypoint.position[0]"
        :key="keypoint.slug"
        v-if="!isTransition"
        v-for="(keypoint, index) in children"
      />
    </transition-group>
    <transition name="t-fader">
      <app-home-button v-if="!isTransition" />
    </transition>
    <transition name="t-fader">
      <switch-places v-if="!isTransition" />
    </transition>
  </div>
</template>

<script>
import gsap from 'gsap'

import { webGL } from '@/webGL/WebGL'
import { FitTo } from '@/utils/FitTo'

import {
  Vector3,
  Box3,
  SphereGeometry,
  DoubleSide,
  Mesh,
  MeshBasicMaterial,
  Raycaster,
  Vector2
} from 'three'

import Keypoint from '@/components/Keypoint'
import Panel from '@/components/Panel'
import AppHomeButton from '@/components/common/AppHomeButton'
import SwitchPlaces from '@/components/common/SwitchPlaces'
import Popin from '@/components/common/Popin'

import { transition } from '@/Transition'
import { CameraWalk } from '@/utils/CameraWalk'

const yAxis = new Vector3(0, 1, 0)
const raycaster = new Raycaster()

export default {
  components: {
    Keypoint,
    AppHomeButton,
    SwitchPlaces,
    Panel,
    Popin
  },

  data () {
    return {
      isTransition: false
    }
  },

  computed: {
    data () {
      return this.$store.getters['data/entryBySlug'](this.$route.params.slug)
    },
    children () {
      return this.data.keypointsOnly
    }
  },

  mounted () {
    this.onInit()
  },

  beforeDestroy () {
    this.onDestroy()
  },

  methods: {
    onInit () {
      this.fitTo = new FitTo(webGL.camera)

      this.hitbox = new Mesh(new SphereGeometry(15, 5, 5), new MeshBasicMaterial({
        depthTest: false,
        depthWrite: false,
        side: DoubleSide
      }))
      this.hitbox.material.visible = false

      this.onResize()

      this.hitbox.position.clone(webGL.camera.position)
      this.$hub.on('transition', this.onTransition)

      if (this.$route.params.slug !== 'exterieur') {
        this.walker = new CameraWalk(webGL.camera, webGL.scene, {min: .5, max: 0}, this.$device.isTouch, true, this.$device.isIphone)
      }
      else {
        this.walker = new CameraWalk(webGL.camera, webGL.scene, {min: 0, max: 0}, this.$device.isTouch, false, this.$device.isIphone)
      }

      this.walker.onStartMoving(() => {
        this.$hub.emit('cameraMove', true)
      })
      this.walker.onEndMoving(() => {
        requestAnimationFrame(()=>{
          this.$hub.emit('cameraMove', false)
        })
      })

      this.$el.addEventListener(this.$device.pointerdown, this.onPointerdown)
      this.$el.addEventListener(this.$device.pointerup, this.onPointerup)
      this.$el.addEventListener(this.$device.pointermove, this.onPointermove)

      this.walker.active = true
    },
    onDestroy () {
      this.$hub.off('transition', this.onTransition)

      this.walker.isActive = false
      this.walker.onDestroy()

      this.$el.removeEventListener(this.$device.pointerdown, this.onPointerdown)
      this.$el.removeEventListener(this.$device.pointerup, this.onPointerup)
      this.$el.removeEventListener(this.$device.pointermove, this.onPointermove)
    },
    onPointerdown (event) {
      this.walker.onPointerdown(this.$device.getPointerPosition(event))
    },
    onPointerup (event) {
      this.walker.onPointerup()
    },
    onPointermove (event) {
      this.walker.onPointermove(this.$device.getPointerPosition(event))
    },

    onTransition (value) {
      this.isTransition = value
      this.walker.active = !value
    },

    onUpdate () {
      if (transition.isTransition) { return }
    },

    computeLook () {
      raycaster.setFromCamera(new Vector2(0, 0), webGL.camera)

      const intersects = raycaster.intersectObject( this.hitbox, false )

      if (intersects.length) {
        const look = new Vector3(intersects[0].point.x, intersects[0].point.y, intersects[0].point.z)
        webGL.camera._lookAt.copy(look)
        webGL.camera._lastLookAt.copy(look)
      }
    },

    getParams (index) {
      return {
        ...this.$local('Keypoint'),
        params: {
          slug: this.$route.params.slug,
          place: this.data.keypointsOnly[index].slug
        }
      }
    }
  },

  watch: {
    $route () {
      this.onDestroy()
      this.onInit()
    }
  }
}
</script>