<template>
  <div class="popin__wrapper u-wrapper-panel u-row u-center u-middle">
    <div class="popin u-relative">
      <div @click="$emit('close')" class="popin__button u-cursor-pointer u-bg-yellow u-z-back">
        <div class="u-full-width u-full-height">
          <svg class="u-fit-contain u-fill-green" >
            <use xlink:href="#close "/>
          </svg>
        </div>
      </div>
      <app-image
        v-if="mediaType(media.extension) === 'image'"
        :id="media.id"
        :width="1920"
        class="u-block u-radius u-fit-contain"
      />
      <video-player
        v-else
        :isWrapperPanel="false"
        :src="media.url"
        class="u-block u-radius u-fit-contain"
      />
    </div>
  </div>
</template>

<script>
import MediaType from '@/mixins/MediaType'
import VideoPlayer from '@/components/common/players/VideoPlayer'

export default {
  mixins: [MediaType],

  components: {
    VideoPlayer
  },

  props: {
    media: {
      type: Object,
      default: () => { return {} }
    }
  }
}
</script>

<style lang="stylus">
@import '~@/styles/settings/variables'

.popin
  width 80vw
  height 80vh

  &__wrapper
    &:before
      content ''
      position absolute
      top 0
      left 0
      height 100%
      width 100%
      background-color: $colors.black
      opacity .5

  &__button
    position absolute
    top -30px
    left calc(50% - 30px)
    height 60px
    width 60px
    padding 18px
    border-radius 50%
    
</style>