<template>
  <div class="introduction u-wrapper-panel">
    <div class="u-full-height u-full-width u-row u-center u-middle u-relative u-pointer-none">
      <!-- <img src="/images/m.svg" class="introduction__logo" alt="M"> -->
      <div class="u-w7of12 u-w10of12--sm">
        <div class="t-h1 u-center u-uppercase">
          Découvrez nos engagements en restaurant
        </div>
      </div>
    </div>
    <div class="introduction__button u-center">
      <app-button :to="$local('RestaurantFirst')" :isWhite="true">
        <span class="t-h2 u-uppercase">
          <div class="u-inline-block">
            Continuer
          </div>
        </span>
      </app-button>
    </div>
  </div>
</template>

<script>
import AppButton from '@/components/common/AppButton'
import { webGL } from '@/webGL/WebGL'

export default {
  components: {
    AppButton
  },

  mounted () {
    webGL.camera.position.set(-10, 10, 27)
    webGL.camera.look.set(-0, 0, 5)
    webGL.camera.forceUpdateLook = true
  }
}
</script>

<style lang="stylus">
@import '~@/styles/settings/variables'
@import '~@/styles/settings/mixins/mq'

.introduction
  &__logo
    position absolute
    top $spacings.md
    width 300px
    left calc(50% - 150px)
    
    +mq($until: 'tablet')
      width 120px
      left calc(50% - 60px)
    
    // @media (max-height 400px)
    //   width 120px

  &:before
    content ''
    position absolute
    top 0
    left 0
    height 100%
    width 100%
    opacity .4
    background-color: $colors.black
  
  &__button
    position absolute
    bottom $spacings.md
    left 0
    width 100%
    
    &__svg
      width 1em
      height 1em
</style>