import Vue from 'vue'
import App from './App.vue'
import router from './router'
import VueI18n from 'vue-i18n'
import store from './store'

import Local from '@/plugins/Local'
import Device from '@/plugins/Device'
import Offset from '@/plugins/Offset'
import Hub from '@/plugins/Hub'
import messages from '@/locales'
import Dom3d from '@/plugins/Dom3d'

import AppImage from '@/components/common/AppImage'

import { transition } from '@/Transition'

import * as Sentry from '@sentry/vue'
import { BrowserTracing } from '@sentry/tracing'

window.isFullLoaded = false

Vue.use(Hub)
Vue.use(Local)
Vue.use(Device)
Vue.use(Offset)
Vue.use(VueI18n)
Vue.use(Dom3d)

Vue.component('app-image', AppImage)

export const i18n = new VueI18n({
  locale: 'fr',
  messages
})

transition.store = store
transition.router = router

class vueArtwork {
  constructor () {
    new Vue({
      router,
      store,
      i18n,
      render: h => h(App)
    }).$mount('#app')

    this.initSentry()
  }

  initSentry () {
    Sentry.init({
      Vue,
      dsn: process.env.VUE_APP_SENTRY,
      integrations: [
        new BrowserTracing({
          routingInstrumentation: Sentry.vueRouterInstrumentation(router),
          tracingOrigins: [process.env.VUE_APP_CRAFT]
        }),
      ],
      tracesSampleRate: 1.0
    })
  }
}

new vueArtwork()