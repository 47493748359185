import Vue from 'vue'
import Router from 'vue-router'

import Restaurant from './pages/Restaurant'
import Introduction from './pages/Introduction'
import Place from './pages/Place'
import Keypoint from './pages/Keypoint'
import messages from './locales'

Vue.use(Router)

const routes = [
  {
    path: '',
    name: 'Restaurant',
    component: Restaurant,
    meta: {
      isFull: true,
      isWebGL: true
    }
  },
  {
    path: '',
    name: 'RestaurantFirst',
    component: Restaurant,
    props: { isFirst: true },
    meta: {
      isFull: true,
      isWebGL: true
    }
  },
  {
    path: 'introduction',
    name: 'Introduction',
    component: Introduction,
    meta: {
      isFull: true,
      isWebGL: true
    }
  },
  {
    path: ':slug',
    name: 'Place',
    component: Place,
    meta: {
      isFull: true,
      isWebGL: true
    }
  },
  {
    path: ':slug/:place',
    name: 'Keypoint',
    component: Keypoint,
    meta: {
      isFull: true,
      isWebGL: true
    }
  },
  {
    path: '*',
    redirect: 'Introduction'
  }
]

let langRoutes = []

for (let i = 0; i < routes.length; i++)  {
  for (const locale of Object.keys(messages)) {
    const prefix = locale === 'en' ? '' : locale + '/'
    let route = {...routes[i]}
    route.path = '/' + prefix + route.path
    route.name = prefix + route.name
    route.meta = {
      ...routes[i].meta,
      locale: locale
    }

    langRoutes.push(route)
  }
}

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL || '/',
  routes: langRoutes
})

export default router