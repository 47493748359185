<template>
  <component
    :is="to.name ? 'router-link' : 'div'"
    :to="to"
    class="keypoint u-cursor-pointer"
    :class="{'is-active': isVisible}"
    @click.stop="$emit('click')"
  >
    <div class="keypoint__title u-bg-green u-white t-text--small">
      {{ title }}
    </div>
    <div class="keypoint__main u-inline-block">
      <svg class="keypoint__main__image keypoint__main__image--picto u-wrapper-panel u-fill-green" preserveAspectRatio="xMidYMid meet" >
        <use :xlink:href="'#' + slug"/>
      </svg>
      <app-image :id="img" class="keypoint__main__image keypoint__main__image--illu u-wrapper-panel u-fit-cover" />
    </div>
  </component>
</template>

<script>
import Object2d from '@/plugins/Dom3d/mixin2d'
import { webGL } from '@/webGL/WebGL'

export default {
  mixins: [Object2d],

  created () {
    this._camera = webGL.camera
  },

  props: {
    title: {
      type: String,
      default: ''
    },
    isVisible: {
      type: Boolean,
      default: true
    },
    img: {
      type: String,
      default: ''
    },
    slug: {
      type: String,
      default: ''
    },
    to: {
      type: Object,
      default: () => {return {}}
    }
  }
}
</script>

<style lang="stylus" scoped>
@import '~@/styles/settings/variables'
@import '~@/styles/settings/mixins/mq'
@import '~@/styles/settings/easings'

.keypoint    
  &__title
    padding .6em 40px .5em 1em
    // height 30px
    border-radius 20px 0 0 20px

  &__main
    border-radius 50%
    width 43px
    height 43px
    transition opacity .3s linear
    background-color: $colors.yellow
    position absolute
    top -5px
    left calc( 100% - 30px )

    &__image

      &--picto
        padding 10px

      &--illu
        border-radius 50%
        opacity 0
        transform scale(0, 0) translateZ(0)
        transition: .3s transform ease-in-out-quad, .3s opacity linear
  
  &:hover &__main__image
    &--illu
      opacity 1
      transform scale(1, 1) translateZ(0)
    
  
</style>