<template>
  <div
    @click.stop="() => {}"
    class="panel u-cursor-default"
    :class="{'u-overflow-y-scroll u-invisible-scroll': $device.isTablet}"
    ref="panel"
  >
    <div class="panel__wrapper u-pad-md u-bg-green u-relative">
      <svg v-if="$device.isTablet" @click="onClickArrow" class="panel__arrow u-round u-fit-contain u-fill-yellow u-bg-green" :class="{'is-open': isScolled}">
        <use xlink:href="#arrow-down"/>
      </svg>
      <!-- :style="{transform: `translateY(${isOpen || !$device.isTablet ? '0' : 'calc(100% - 200px)'})`}" -->
      <div class="u-full-height u-relative">
        <div class="panel__top u-row u-middle u-pad-b-xl u-pad-b-md--sm">
          <div v-if="$device.isTablet" class="u-w12of12">
            <div class="u-row u-between">
              <router-link v-if="prevKeypoint" :to="prevKeypoint" class="panel__button u-cursor-pointer u-bg-yellow">
                <div class="u-full-width u-full-height">
                  <svg class="u-fit-contain u-fill-green" >
                    <use xlink:href="#arrow-left"/>
                  </svg>
                </div>
              </router-link>
              <router-link v-if="nextKeypoint" :to="nextKeypoint" class="panel__button u-cursor-pointer u-bg-yellow">
                <div class="u-full-width u-full-height">
                  <svg class="u-fit-contain u-fill-green" >
                    <use xlink:href="#arrow-right"/>
                  </svg>
                </div>
              </router-link>
            </div>
          </div>
        </div>
        <div
          class="panel__content"
          :class="{'u-overflow-y-scroll u-invisible-scroll u-pad-b-lg': !$device.isTablet}"
        >
          <!-- :class="$device.isTablet ? 'u-overflow-y-hidden' : 'u-overflow-y-scroll'" -->
          <div class="panel__title t-h1 u-yellow">
            {{data.title}}
          </div>
          <div v-if="data.media.length" class="u-marg-t-sm u-relative">
            <app-image
              v-if="mediaType(data.media[0].extension) === 'image'"
              :id="data.media[0].id"
              :width="600"
              class="u-block u-full-width u-radius u-cursor-pointer"
              @click="$emit('popin', data.media[0])"
            />
            <template v-else>
              <div class="u-box-16by9">
                <video-player :src="data.media[0].url" />
                <!-- <div
                  class="u-wrapper-panel u-cursor-pointer"
                  @click="$emit('popin', data.media[0])"
                /> -->
              </div>
            </template>
          </div>
          <div class="t-wysiwyg u-white-space u-marg-t-sm" v-html="data.text" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>  
import MediaType from '@/mixins/MediaType'
import VideoPlayer from '@/components/common/players/VideoPlayer'

import gsap from 'gsap'

export default {
  mixins: [MediaType],

  components: {
    VideoPlayer
  },

  data () {
    return {
      isScolled: false
    }
  },

  props: {
    data : {
      type: Object,
      default: () => { return {} }
    },
    entry: {
      type: Object,
      default: () => { return {} }      
    }
  },

  mounted () {
    if (this.$device.isTablet) {
      this.$refs.panel.addEventListener('scroll', this.onScroll)
    }
  },

  methods: {
    onScroll () {
      const scrollTop = this.$refs.panel.scrollTop

      if (scrollTop > 0 && scrollTop > this.$device.height - 400) {
        this.isScolled = true
      }
      else {
        this.isScolled = false
      }
    },

    onClickArrow () {
      const scroll = {top: this.$refs.panel.scrollTop}

      if (this.isScolled) {
        gsap.to(scroll, {
          top: 0,
          duration: 1,
          ease: 'power3.inOut',
          onUpdate: value => {
            this.$refs.panel.scrollTo(0, scroll.top)
          }
        })
      }
      else {
        gsap.to(scroll, {
          top: this.$device.height - 200,
          duration: 1,
          ease: 'power3.inOut',
          onUpdate: value => {
            this.$refs.panel.scrollTo(0, scroll.top)
          }
        })
      }
    }
  },

  watch: {
    isScolled () {
      this.$emit('scroll', this.isScolled)
    }
  },

  computed: {
    nextKeypoint () {
      let target = this.entry.keypointsOnly[this.data.indexKeypoint+1]

      if (!target) {
        target = this.entry.keypointsOnly[0]
        return {
          ...this.$local('Keypoint'),
          params: {
            slug: this.entry.slug,
            place: target.slug
          }
        }
      }

      return {
        ...this.$local('Keypoint'),
        params: {
          slug: this.entry.slug,
          place: target.slug
        }
      }
    },

    prevKeypoint () {
      let target = this.entry.keypointsOnly[this.data.indexKeypoint-1]

      if (!target) {
        target = this.entry.keypointsOnly[this.entry.keypointsOnly.length - 1]
        return {
          ...this.$local('Keypoint'),
          params: {
            slug: this.entry.slug,
            place: target.slug
          }
        }
      }

      return {
        ...this.$local('Keypoint'),
        params: {
          slug: this.entry.slug,
          place: target.slug
        }
      }
    }
  }
}
</script>

<style lang="stylus">
@import '~@/styles/settings/variables'
@import '~@/styles/settings/mixins/mq'
@import '~@/styles/settings/easings'

.panel
  position absolute
  right 0
  top 0
  height 100%
  width 450px
  
  &__title
    +mq($until: 'tablet')
      height 60px

  &__wrapper
    transition transform 1s ease-in-out-quart
    +mq($until: 'tablet')
      border-radius 15px 15px 0 0
    +mq($from: 'tablet')
      height 100%

  +mq($until: 'tablet')
    width 100%
    top auto
    bottom 0
    padding-top calc( 100vh - 200px )

  &__more
    padding 7px 20px
    position absolute
    top 0
    left 50%
    transform translate(-50%, -50%)

  &__content
    +mq($from: 'tablet')
      height calc(100% - 60px)

  &__arrow
    height 60px
    width 60px
    position absolute
    top -30px
    left calc( 50% - 30px )
    padding 15px 15px 12px
    transform scale(1, -1)
    transition transform .5s ease-in-out-quart
    
    &.is-open
      transform scale(1, 1)

  &__button
    height 45px
    width 45px
    padding 15px
    border-radius 50%

    
</style>