<template>
  <img class="c-img" :src="fakeSRC" @click="$emit('click')">
</template>

<script>
import { api } from '@/store/interfaces/apiCraft'

export default {
  props: {
    id: {
      type: String,
      required: true
    },
    width: {
      type: Number,
      default: 0
    },
    height: {
      type: Number,
      default: 0
    },
    margins: {
      type: Number,
      default: 100
    }
  },

  data () {
    return {
      src: '',
      isInViewport: false
    }
  },

  computed: {
    fakeSRC() {
      return this.isInViewport ? this.src : "";
    }
  },

  created () {
    this.getImage()
  },

  mounted () {
    this.onResize()
  },

  methods: {
    async getImage () {
      const args = {id: this.id}

      if (this.width !== 0) {
        args.width = this.width
      }
      if (this.height !== 0) {
        args.height = this.height
      }

      const { cache, request } = api.imgSize(args)

      cache.then(this.treatImage)
      request.then(this.treatImage)
    },

    treatImage (img) {
      if (img === null) { return }

      img = img.replace('$PRIMARY_SITE_URL', process.env.VUE_APP_CRAFT)
      this.src = img
      this.$emit('src', img)
      this.$el.classList.add('is-loaded')      
    },

    onResize() {
      this.offset = this.$offset.absolute(this.$el)
      this.onScroll()
    },

    onScroll() {
      if( this.isInViewport ) { return }

      this.isInViewport = this.getIsInViewport(this.offset.top - this.margins, Infinity)
    },

    getIsInViewport(offsetTop, height) {
      if( offsetTop + height > this.$device.scroll.top && offsetTop < this.$device.scroll.top + this.$device.height ) {
        return true;
      }
      else {
        return false;
      }
    }
  },

  watch: {
    id () {
      this.getImage()
    },
    width () {
      this.getImage()
    },
    height () {
      this.getImage()
    }
  }
}
</script>