import {
  WebGLRenderer,
  sRGBEncoding,
  PCFSoftShadowMap,
  ReinhardToneMapping,
  ACESFilmicToneMapping,
  WebGLRenderTarget,
  WebGLMultisampleRenderTarget,
  LinearFilter,
  RGBFormat
} from 'three'

import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer.js'
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass.js'

import { BokehPass } from 'three/examples/jsm/postprocessing/BokehPass.js'

// import { gui, guiObj } from '@/utils/gui'

export class Renderer {
  constructor(scene, camera) {
    this.scene = scene
    this.camera = camera
    
    this.usePostprocess = true

    this.resize = this.resize.bind(this)
    this.update = this.update.bind(this)
    this.matChanger = this.matChanger.bind(this)

    this.setInstance()
    this.setPostProcess()
  }

  setInstance () {
    this.clearColor = 0x7eabcb

    // Renderer
    this.renderer = new WebGLRenderer({
      alpha: false,
      antialias: true,
    })
    this.renderer.setClearColor(this.clearColor)

    // this.renderer.physicallyCorrectLights = true
    this.renderer.outputEncoding = sRGBEncoding
    // this.renderer.shadowMap.type = PCFSoftShadowMap
    // this.renderer.shadowMap.enabled = true
    // this.renderer.toneMapping = ReinhardToneMapping
    // this.renderer.toneMapping = ACESFilmicToneMapping
    this.renderer.toneMappingExposure = 1.3
    // this.renderer.gammaOutPut = true
    // this.renderer.gammaFactor = 2.2

    this.context = this.renderer.getContext()

    // Add stats panel
    if (this.stats) {
      this.stats.setRenderPanel(this.context)
    }
  }

  setPostProcess () {
    this.postProcess = {}

    /**
     * Render pass
     */
    this.postProcess.renderPass = new RenderPass(this.scene, this.camera)

    /**
     * Effect composer
     */
    const RenderTargetClass = window.devicePixelRatio >= 2 ? WebGLRenderTarget : WebGLMultisampleRenderTarget
    // const RenderTargetClass = WebGLRenderTarget
    this.renderTarget = new RenderTargetClass(
      window.innerWidth,
      window.innerHeight,
      {
        generateMipmaps: false,
        minFilter: LinearFilter,
        magFilter: LinearFilter,
        format: RGBFormat,
        encoding: sRGBEncoding
      }
    )
    this.postProcess.composer = new EffectComposer(this.renderer, this.renderTarget)
    this.postProcess.composer.setSize(window.innerWidth, window.innerHeight)
    this.postProcess.composer.setPixelRatio(window.devicePixelRatio)

    /**
     * Bokeh Pass
     */
    this.postProcess.bokehPass = new BokehPass(this.scene, this.camera, {
      focus: 13,
      aperture: .0001,
      maxblur: .009
    })

    // gui.add( guiObj, 'focus', 0, 30.0, 1 ).onChange( this.matChanger );
    // gui.add( guiObj, 'aperture', 0, 20, 0.1 ).onChange( this.matChanger );
    // gui.add( guiObj, 'maxblur', 0.0, 0.02, 0.001 ).onChange( this.matChanger );

    this.postProcess.composer.addPass(this.postProcess.renderPass)
    this.postProcess.composer.addPass(this.postProcess.bokehPass)
  }

  matChanger () {
    // this.postProcess.bokehPass.uniforms[ 'focus' ].value = guiObj.focus
    // this.postProcess.bokehPass.uniforms[ 'aperture' ].value = guiObj.aperture * 0.00001
    // this.postProcess.bokehPass.uniforms[ 'maxblur' ].value = guiObj.maxblur
  }

  resize ({width, height}) {
    // Instance
    this.renderer.setSize(width, height)
    this.renderTarget.setSize(width, height)
    this.renderer.setPixelRatio(window.devicePixelRatio)

    // Post process
    this.postProcess.composer.setSize(width, height)
    this.postProcess.composer.setPixelRatio(window.devicePixelRatio)

    this.camera.aspect = width / height
    this.camera.updateProjectionMatrix()
  }

  update () {
    if (this.usePostprocess) {
      this.postProcess.composer.render()
    }
    else {
      this.renderer.render(this.scene, this.camera)
    }

    if (this.stats) {
      this.stats.afterRender()
    }
  }

  get canvas () {
    return this.renderer.domElement
  }

  destroy () {
    this.renderer.renderLists.dispose()
    this.renderer.dispose()
    this.renderTarget.dispose()
    this.postProcess.composer.renderTarget1.dispose()
    this.postProcess.composer.renderTarget2.dispose()
  }
}
