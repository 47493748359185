<template>
  <router-link :to="$local('Restaurant')"  class="home-button u-flex u-left u-middle">
    <div class="home-button__icon u-bg-green">
      <div class="u-full-width u-full-height">
        <svg class="u-fit-contain u-fill-yellow" >
          <use xlink:href="#home"/>
        </svg>
      </div>
    </div>
    <div class="t-h2 u-uppercase u-marg-l-sm u-yellow" v-if="!$device.isMobile">
      {{ name }}
    </div>
  </router-link>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="stylus">
@import '~@/styles/settings/variables'
@import '~@/styles/settings/mixins/mq'

.home-button
  position absolute
  top: $spacings.md
  left: $spacings.md

  +mq($until: 'tablet')
    top: $spacings.sm !important
    left: $spacings.sm !important

  &__icon
    height 80px
    width 80px
    padding 23px
    border-radius 50%

    +mq($until: 'tablet')
      height 60px
      width 60px
      padding 18px
</style>