<template>
  <component
    :is="to.name ? 'router-link' : 'div'"
    :to="to"
    class="keypoint u-cursor-pointer"
    :class="{'is-active': isVisible}"
    @click.stop="$emit('click')"
  >
    <div class="keypoint__main u-box-1by1" />
    <div class="keypoint__pulse" :class="{'is-active': !isSelected}"></div>
    <div class="keypoint__heart"></div>
  </component>
</template>

<script>
import Object2d from '@/plugins/Dom3d/mixin2d'
import { webGL } from '@/webGL/WebGL'

export default {
  mixins: [Object2d],

  created () {
    this._camera = webGL.camera
  },

  props: {
    isSelected: {
      type: Boolean,
      default: false
    },
    isVisible: {
      type: Boolean,
      default: true
    },
    to: {
      type: Object,
      default: () => {return {}}
    }
  }
}
</script>

<style lang="stylus" scoped>
@import '~@/styles/settings/variables'
@import '~@/styles/settings/mixins/mq'
@import '~@/styles/settings/easings'

.keypoint
  border-radius 50%
  width 18px
  opacity 0
  transition opacity .3s linear
  
  &.is-active
    opacity 1
  
  &__main
    border-radius 50%
    background-color $colors.yellow

  &__heart
    position absolute
    background: $colors.yellow
    height 30%
    width 30%
    top 35%
    left 35%
    border-radius 50%

  &__pulse
    position absolute
    border solid 3px $colors.yellow
    height 200%
    width 200%
    top -50%
    left -50%
    border-radius 50%
    opacity 0

    &.is-active
      animation 2s ease-in-out-quad infinite pulse



@keyframes pulse
  0%
    transform: translateZ(0) scale(.5)
    opacity 0
  20%
    opacity 1

  100%
    transform: translateZ(0) scale(1)
    opacity 0
</style>