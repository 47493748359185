import { LoaderManager, Component } from 'shimmer'

// import { HDRCubeTextureLoader } from 'three/examples/jsm/loaders/HDRCubeTextureLoader'
import {
  AmbientLight,
  DirectionalLight,
  Box3,
  HalfFloatType,
  CubeTextureLoader,
  CubeReflectionMapping,
  MeshPhysicalMaterial,
  MeshBasicMaterial,
  DoubleSide,
  TextureLoader
} from 'three'

import { gui } from '@/utils/gui'

export class Environment extends Component {
  constructor () {
    super()

    this.addLight()
    this.loadTexture()
    this.loadAlphas()

    this.alphaFries = null
    this.alphaDonuts = null
    this.alphaTree = null
  }

  load (obj = 'ktx', isIpad = false) {
    const promise = LoaderManager.load(obj, false, obj => {
      obj.castShadow = false
      obj.receiveShadow = false

      // this is a dirty fix for transparent textures
      // if (obj.name.startsWith('frites') || obj.name.startsWith('plan_donut_alpha') || obj.name.startsWith('trees')) {
      // with ktx
      if (obj.name.startsWith('frites') || obj.name.startsWith('plan_donut_alpha') || obj.name.startsWith('trees')) {
        const texture = obj.material.map
        obj.material.dispose()
        obj.material = new MeshBasicMaterial({
          transparent: true,
          opacity: 1,
          side: DoubleSide,
          map: texture
        })

        if (isIpad) {
          if (obj.parent.name.startsWith('frites')) {
            obj.material.alphaMap = this.alphaFries
          } else if (obj.parent.name.startsWith('plan_donut_alpha')) {
            obj.material.alphaMap = this.alphaDonuts
          } else if (obj.parent.name.startsWith('trees')) {
            obj.material.alphaMap = this.alphaTree
          }
        }
      }
    })

    promise.then(obj => {
      this.add(obj[0].object)
      this.createGlass()
    })

    return promise
  }

  addLight () {
    this.ambient = new AmbientLight(0xffffff, 1)
    this.add(this.ambient)
  }

  createGlass () {
    this.glass = this.getByName('vitres', false)
    const texture = this.glass.material.map
    this.glass.material.dispose()
    this.glass.material = this.material
    this.material.needsUpdate = true
  }

  get baseViewBox () {
    const box = new Box3().makeEmpty()

    const building = this.getObjectByName('batiment')
    building.updateMatrixWorld(true)
    box.union(new Box3().setFromObject(building))

    const truck = this.getObjectByName('camion')
    truck.updateMatrixWorld(true)
    box.union(new Box3().setFromObject(truck))

    return box
  }

  loadAlphas () {
    const loader = new TextureLoader()
    const promises = []

    promises.push(
      new Promise(resolve => {
        loader.load('/images/textures/alpha_donuts.jpg', texture => {
          this.alphaDonuts = texture
          this.alphaDonuts.flipY = false
          resolve()
        })
      }),
      new Promise(resolve => {
        loader.load('/images/textures/alpha_fries.jpg', texture => {
          this.alphaFries = texture
          this.alphaFries.flipY = false
          resolve()
        })
      }),
      new Promise(resolve => {
        loader.load('/images/textures/alpha_tree.jpg', texture => {
          this.alphaTree = texture
          this.alphaTree.flipY = false
          resolve()
        })
      })
    )

    return Promise.all(promises)
  }

  loadTexture () {
    return new Promise( (resolve, reject) => {
      this.envTexture = new CubeTextureLoader().load([
          'images/cubemaps/rainbow/posx.jpg',
          'images/cubemaps/rainbow/negx.jpg',
          'images/cubemaps/rainbow/posy.jpg',
          'images/cubemaps/rainbow/negy.jpg',
          'images/cubemaps/rainbow/posz.jpg',
          'images/cubemaps/rainbow/negz.jpg'
      ])
      // this.envTexture.mapping = CubeReflectionMapping

      this.material = new MeshPhysicalMaterial({
        color: 0xb2ffc8,
        envMap: this.envTexture,
        metalness: .1,
        roughness: .1,
        transparent: true,
        transmission: 1,
        side: DoubleSide,
        clearcoat: 1,
        clearcoatRoughness: 0.25,
        reflectivity: .4,
        envMapIntensity: 0.9
      })

      this.addGui()
      resolve()
    })
  }

  addGui () {
    // const options = {transmission: true}
    // const guiGlass = gui.addFolder('Glass')
    // guiGlass.add(this.material, 'metalness').min(0).max(1).step(0.01)
    // guiGlass.add(this.material, 'roughness').min(0).max(1).step(0.01)
    // guiGlass.add(this.material, 'transmission').min(0).max(1).step(0.01)
    // guiGlass.add(this.material, 'clearcoat').min(0).max(1).step(0.01)
    // guiGlass.add(this.material, 'clearcoatRoughness').min(0).max(1).step(0.01)
    // guiGlass.add(this.material, 'reflectivity').min(0).max(1).step(0.01)
    // guiGlass.add(this.material, 'envMapIntensity').min(0).max(1).step(0.01)
  }
}

export const environment = new Environment()