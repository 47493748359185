<template>
  <div class="switcher u-bg-green u-row u-between">
    <router-link
      :to="{...$local('Place'), params: {slug: place.slug}}"
      :style="{width: `calc(${100/places.length}% - ${$device.isMobile ? 7 : 15}px)`}"
      :key="place.index"
      v-for="(place, index) in places"
      class="u-center"
    >
      <div
        class="switcher__item u-w8of12 u-box-1by1 u-round u-overflow-hidden u-inline-block"
        :class="place.slug === $route.params.slug ? 'u-bg-yellow u-fill-green' : 'u-bg-green u-fill-yellow switcher__item--bordered' "
      >
        <svg class="switcher__picto u-wrapper-panel" preserveAspectRatio="xMidYMid meet" >
          <use :xlink:href="'#' + place.slug"/>
        </svg>
      </div>
      <div class="switcher__place__text t-text--small u-yellow u-center u-marg-t-sm" :class="{'t-slab': place.slug === $route.params.slug}">
        {{ place.title }}
      </div>
    </router-link>
  </div>
</template>

<script>
export default {
  computed: {
    places () {
      return this.$store.getters['data/entries']
    }
  }
}
</script>

<style lang="stylus">
@import '~@/styles/settings/variables'
@import '~@/styles/settings/mixins/mq'

.switcher
  position absolute
  bottom 0
  left calc(50% - 210px)
  width 420px
  border-radius 15px 15px 0 0
  padding 20px 20px 10px

  +mq($until: 'tablet')
    width 100%
    left 0
  
  &__item--bordered
    border: solid 1px $colors.yellow
  
  &__picto
    padding 15px
  
  &__place
    &__text
      margin-top .5em
</style>