import Vue from 'vue'
import Vuex from 'vuex'

import global from '@/store/modules/global'
import data from '@/store/modules/data'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    global,
    data
  }
})