
<template>
  <button
    class="t-button u-cursor-pointer u-center t-text"
    :class="{
      'is-white': isWhite,
      'is-ghost': isGhost,
      'is-small': isSmall
    }"
    @click="$emit('click')"
  >
    <template v-if="url !== ''" >
      <a v-if="isUrlAbsolute" :href="url" target="_blank" class="u-relative">
        <slot/>
      </a>
      <router-link v-else :to="url" class="u-relative">
        <slot/>
      </router-link>
    </template>
    <router-link v-else-if="to.name" :to="to" class="u-relative">
      <slot/>
    </router-link>
    <span v-else class="u-relative" :class="{'u-flex u-center u-middle': icon}">
      <slot/>
    </span>
  </button>
</template>

<script>
import Button from './Button'

export default {
  mixins: [Button],

  props: {
    icon: {
      type: Boolean,
      default: false
    },
    isWhite: {
      type: Boolean,
      default: false
    },
    isGhost: {
      type: Boolean,
      default: false
    },
    isSmall: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="stylus">
@import '~@/styles/settings/variables'
@import '~@/styles/settings/mixins/mq'
@import '~@/styles/settings/easings'

.t-button
  position relative
  padding: clamp(12px, .9em, 20px) clamp(30px, 2.8em, 60px) clamp(10px, .8em, 20px)
  color: $colors.white
  background-color transparent
  z-index 0
  
  &.is-small
    padding: clamp(5px, .5em, 10px) clamp(10px, 1.5em, 20px) clamp(7px, .7em, 12px)

  &:after
    content ''
    z-index -1
    position absolute
    top 0
    left 0
    width 100%
    height 100%
    border-radius 100px
    background-color: $colors.green
    transition: transform .5s ease-in-out-quart

  &.is-white
    color: $colors.green

    &:after
      background-color: $colors.white
  
  &.is-ghost.is-white
    color: $colors.white

    &:after
      border: solid 3px $colors.white

  &.is-ghost
    color: $colors.green

    &:after
      border: solid 2px $colors.green
      background-color: transparent
  
  +mq($from: 'tablet')
    &:hover
      &:after
        transform scale(1.2, 1.2)

</style>