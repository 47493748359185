<template>
  <div class="u-wrapper-panel" :class="{'is-grabbing': isGrabbing}">
    <transition-group name="t-fader">
      <keypoint-full
        v-for="(place, index) in places"
        v-if="!isTransition && $device.isDesktop"
        :position="place.position[0]"
        :to="getParams(index)"
        :title="place.title"
        :key="place.slug"
        :slug="place.slug"
        :img="place.image.length ? place.image[0].id : ''"
      />
    </transition-group>
    <img src="/images/m.svg" class="restaurant__logo">
    <div v-if="!$device.isMinHeight && !isTransition" class="restaurant__info u-bg-white u-row u-middle u-radius">
      <img class="u-w3of12 restaurant__info__img" src="/images/tuto.png">
      <div class="u-w9of12 t-text--small u-black u-pad-l-sm">
        Choisissez une zone pour la découvrir
      </div>
    </div>

    <transition name="t-fader">
      <switch-places v-if="!$device.isDesktop" />
    </transition>
  </div>
</template>

<script>
import { environment } from '@/webGL/objects/Environment'
import { webGL } from '@/webGL/WebGL'

import { Vector3 } from 'three'
import { FitTo } from 'shimmer'
import { SmoothMouse } from '@/mixins/SmoothMouse'
import { transition } from '@/Transition'

import SwitchPlaces from '@/components/common/SwitchPlaces'
import KeypointFull from '@/components/KeypointFull'

import gsap from 'gsap'

export default {
  name: 'Restaurant',

  mixins: [
    SmoothMouse
  ],

  components: {
    KeypointFull,
    SwitchPlaces
  },

  computed: {
    places () {
      return this.$store.getters['data/entries']
    }
  },

  data () {
    return {
      isGrabbing: false,
      isTransition: false
    }
  },

  props: {
    isFirst: {
      type: Boolean,
      default: false
    }
  },

  created () {
    this.isTransitionCamera = true

    const padding = this.$device.isTablet ? -.15 : .3
    const { position, look } = FitTo.fit(environment.baseViewBox, padding, { vector: new Vector3(-.3, .5, .8) })

    // DIRTY DISPLACE LOOK
    look.z += this.$device.isTablet ? 15 : 10
    look.x -= 5

    this.basePos = position.clone()
    this.baselook = look.clone()

    if (this.isFirst) {
      gsap.to(webGL.camera.position, {
        x: position.x,
        y: position.y,
        z: position.z,
        duration: 2,
        ease: 'power2.inOut',
        onComplete: () => {
          this.isTransitionCamera = false
        }
      })

      gsap.to(webGL.camera.look, {
        x: look.x,
        y: look.y,
        z: look.z,
        duration: 2,
        ease: 'power2.inOut',
        onUpdate: () => {
          webGL.camera.forceUpdateLook = true
        }
      })
    }
    else {
      webGL.camera.position.copy(position)
      webGL.camera.look.copy(look)
      this.isTransitionCamera = false
    }
  },

  mounted () {
    this.$hub.on('transition', this.onTransition)
  },

  destroyed () {
    this.$hub.off('transition', this.onTransition)
  },

  methods: {
    onTransition (value) {
      this.isTransition = value
    },

    onUpdate () {
      if (transition.isTransition || this.isTransitionCamera) { return }

      this._onUpdate()
      webGL.camera.position.x = this.basePos.x + this._smooth.currentPos.x * 2
      webGL.camera.position.y = this.basePos.y + this._smooth.currentPos.y * 2
      webGL.camera.forceUpdateLook = true
    },

    getParams (index) {
      return {
        ...this.$local('Place'),
        params: {
          slug: this.places[index].slug,
          place: this.places[index].keypoints.find(keypoint => keypoint.typeHandle === 'keypoint').slug
        }
      }
    }
  }
}
</script>

<style lang="stylus">
@import '~@/styles/settings/variables'
@import '~@/styles/settings/mixins/mq'

.restaurant
  &__logo
    position absolute
    top $spacings.md
    left $spacings.md
    width 100px
    
    +mq($until: 'tablet')
      width 70px
      top: $spacings.sm !important
      left: $spacings.sm !important

  &__info
    position absolute
    bottom $spacings.md
    left $spacings.md
    width 300px
    padding 15px

    // +mq($until: 'desktop')
    //   bottom $spacings.md

    // +mq($until: 'tablet')
    //   bottom $spacings.md

    +mq($until: 'mobile')
      bottom calc(35vw + 30px)
      right $spacings.md
      width auto

</style>