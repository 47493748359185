import { Box3, Vector3 } from 'three'

import { webGL } from '@/webGL/WebGL'
import gsap from 'gsap'
import { clamp, mapper } from '@/utils/Maths'

import { emitter } from '@/utils/emitter'

export class Transition {
  constructor () {
    this.beforeEach = this.beforeEach.bind(this)
    this.isTransition = false
    this._onScreen = () => {}
    this.promiseLoaded = new Promise(resolve => {
      this.resolve = resolve
    })
  }

  async beforeEach (to, from, next) {
    await this.promiseLoaded
    // DEBUG
    if (to.query.debug) {
      next()
    }
    // // START AT INTRODUCTION
    // else if (!from.name && !to.name.includes('Introduction')) {
    //   next({name: 'Introduction'})
    // }
    // START AT INTRODUCTION
    else if (!from.name) {
      if (to.name.includes('Keypoint') || to.name.includes('Place')) {
        const trajectories = to.name.includes('Place') ? this.computeTrajectoriesFromPlace(to) : this.computeTrajectoriesFromKeypoints(to)
        
        this._onScreen(true)
        setTimeout(() => {
          webGL.camera.position.copy(trajectories[trajectories.length-1].position)
          webGL.camera.look.copy(trajectories[trajectories.length-1].look)
          next()
          this._onScreen(false)
        }, 1600)
      }
      else {
        next()
      }
    }
    // FROM RESTO TO PLACE
    else if (from.name && from.name.includes('Restaurant') && to.name.includes('Place')) {
      const trajectories = this.computeTrajectoriesFromPlace(to)
      this.doEase(trajectories, next)
    }
    // FROM KEYPOINT TO PLACE
    else if (from.name && to.name.includes('Place')) {
      const trajectories = this.computeTrajectoriesFromPlace(to)

      this._onScreen(true)
      setTimeout(() => {
        webGL.camera.position.copy(trajectories[trajectories.length-1].position)
        webGL.camera.look.copy(trajectories[trajectories.length-1].look)
        next()
        this._onScreen(false)
      }, 1600)
    }
    // TO KEYPOINT
    else if (from.name && to.name.includes('Keypoint')) {
      // TO KEYPOINT FROM MENU
      if (to.query.fast) {
        const trajectories = this.computeTrajectoriesFromKeypoints(to)
        
        this._onScreen(true)
        setTimeout(() => {
          webGL.camera.position.copy(trajectories[trajectories.length-1].position)
          webGL.camera.look.copy(trajectories[trajectories.length-1].look)
          next()
          this._onScreen(false)
        }, 1600)
      }
      // TO KEYPOINT FROM ESLEWHERE
      else {
        const trajectories = this.computeTrajectoriesFromKeypoints(to)
        this.doEase(trajectories, next)
      }
    }
    // FROM INTRO TO RESTO
    else if (from.name && from.name.includes('Introduction') && to.name.includes('Restaurant')) {
      next()
    }
    else {
      this._onScreen(true)
      setTimeout(() => {
        next()
        this._onScreen(false)
      }, 1600)
    }
  }

  doEase (trajectories, next) {
    this.isTransition = true
    emitter.emit('transition', true)

    this.tlPosition = gsap.timeline({
      onComplete: () => {
        emitter.emit('transition', false)
        next()
        this.isTransition = false
      }
    })
    this.tlLook = gsap.timeline()

    trajectories.forEach(trajectory => {
      this.tlPosition.to(webGL.camera.position, {
        x: trajectory.position.x,
        y: trajectory.position.y,
        z: trajectory.position.z,
        duration: trajectory.duration,
        ease: trajectory.ease
      })

      this.tlLook.to(webGL.camera.look, {
        x: trajectory.look.x,
        y: trajectory.look.y,
        z: trajectory.look.z,
        duration: trajectory.duration,
        ease: trajectory.ease,
        onUpdate: () => {
          webGL.camera.forceUpdateLook = true
        }
      })
    })
  }

  computeTrajectoriesFromPlace (to) {
    const entry = this.$store.getters['data/entryBySlug'](to.params.slug)
    const data = entry.trajectory

    const trajectories = []
    data.forEach(trajectory => {
      trajectories.push(this.computePosition(trajectory.look[0], trajectory.position[0]))
    })

    return this.computeTrajectories(trajectories)
  }

  computeTrajectoriesFromKeypoints (to) {
    const entry = this.$store.getters['data/entryBySlug'](to.params.slug)
    const data = this.$store.getters['data/keypointBySlug'](to.params.slug, to.params.place)
    const trajectories = [this.computePosition(data.look[0], data.position[0], data.zoom)]

    for (let i = data.index - 1; i >= 0; i--) {
      if (entry.keypoints[i].typeHandle === 'transition') {
        trajectories.push(this.computePosition(entry.keypoints[i].look[0], entry.keypoints[i].position[0]))
      }
      // stop compute stuff when reach previous point
      else {
        break
      }
    }

    trajectories.reverse()

    return this.computeTrajectories(trajectories)
  }

  computeTrajectories (trajectories) {
    if (trajectories.length === 1) {
      trajectories[0].ease = 'power2.inOut'
      const dist = webGL.camera.position.distanceTo(trajectories[0].position)
      trajectories[0].duration = this.normalizeDuration(dist)
    }
    else {
      trajectories.forEach((trajectory, index) => {
        // first one
        if (index === 0) {
          trajectory.ease = 'power2.inOut'
          const dist = webGL.camera.position.distanceTo(trajectory.position)
          trajectory.duration = this.normalizeDuration(dist)
        }
        // last one
        else if (index === trajectories.length - 1) {
          trajectory.ease = 'power2.inOut'
          const dist = trajectory.position.distanceTo(trajectories[index - 1].position)
          trajectory.duration = this.normalizeDuration(dist)
        }
        // middle
        else {
          trajectory.ease = 'power2.inOut'
          const dist = trajectory.position.distanceTo(trajectories[index - 1].position)
          trajectory.duration = this.normalizeDuration(dist)
        }
      })
    }

    return trajectories
  }

  normalizeDuration (dist) {
    return clamp(mapper(dist, 1, 50, 1, 3), .5, 6)
  }

  computePosition (dataLook, dataPosition, zoom = 100) {
    const box = new Box3().makeEmpty()
    zoom = 1 + ((100 - zoom)*.5)
    return {
      look: new Vector3(parseFloat(dataPosition.x), parseFloat(dataPosition.y), parseFloat(dataPosition.z)),
      position: new Vector3(
        parseFloat(dataPosition.x) + parseFloat(dataLook.x) * zoom,
        parseFloat(dataPosition.y) + parseFloat(dataLook.y) * zoom,
        parseFloat(dataPosition.z) + parseFloat(dataLook.z) * zoom
      )
    }
  }

  set onScreen (value) {
    this._onScreen = value
  }

  set router (router) {
    this.$router = router
    this.$router.beforeEach(this.beforeEach)

  }

  set store (store) {
    this.$store = store
    this.$store.watch((state, getters) => getters['data/loaded'],
      (newVal, oldVal) => {
        if (newVal) {
          this.resolve()
        }
      }
    )
  }
}

export const transition = new Transition()