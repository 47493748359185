<template>
  <div class="u-wrapper-panel u-bg-green">
    <div class="full__wrapper u-row u-middle u-center u-full-height u-pad-b-xl">
      <div>
        <div class="u-row u-full-width u-center">
          <img class="full__logo u-w3of12" src="/images/m.svg">
        </div>
        <div class="full__text text u-full-width u-center u-pad-x-sm u-marg-t-sm">
          Cette experience<br/>est <span class="t-slab">optimisée en plein écran</span>
        </div>
      </div>
    </div>
    <div class="full__buttons u-pad-x-sm">
      <div class="u-center">
        <app-button @click="$emit('close')" class="u-full-width" :isWhite="true" :isGhost="true">
          <span class="t-h2 u-uppercase">
            <div class="u-inline-block">
              Non merci
            </div>
          </span>
        </app-button>
      </div>
      <div class="u-center u-marg-t-sm">
        <app-button @click="$emit('fullscreen')" class="u-full-width" :isWhite="true" :icon="true">
          <svg class="full__buttons__svg u-fill-green u-marg-r-sm" >
            <use xlink:href="#fullscreen"/>
          </svg>
          <div class="t-h2 u-uppercase u-inline-block">
            OUVRIR plein écran
          </div>
        </app-button>
      </div>
    </div>
  </div>
</template>

<script>
import AppButton from '@/components/common/AppButton'

export default {
  components: {
    AppButton
  }
}
</script>

<style scoped lang="stylus">
@import '~@/styles/settings/variables'

.full
  &__text
    // @extend .u-antialiased
    font-size 20px
    line-height 28px
    
    @media (max-height 400px)
      font-size 15px
      line-height normal

  &__logo
    @media (max-height 400px)
      width 12%

  &__wrapper
    @media (max-height 400px)
      padding-bottom 50%
  
  &__buttons
    position absolute
    bottom: $spacings.md
    left 0
    width 100%
    
    &__svg
      width 2em
      height 2em
</style>