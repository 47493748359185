<template>
  <div
    id="app"
    :class="{'is-full': $route.meta.isFull}"
    class="u-relative u-overflow-hidden u-antialiased u-cursor-grab"
  >
    <SymbolsSvg />
    <div ref="containerWebGL" class="u-wrapper-panel"/>
    <transition name="t-fader">
      <router-view @isSearch="e => isBtnMenuVisible = e" v-if="isReady && !$route.query.debug" />
    </transition>
    <div ref="containerCSS3D" class="u-wrapper-panel u-overflow-hidden u-pointer-none"/>
    <transition name="t-fader">
      <fullscreen-screen
        v-if="($device.isMobile && !$device.isIphone) && !$route.query.debug && isFullScreenScreen"
        :progress="progress"
        @close="closeFullscreenScreen(false)"
        @fullscreen="closeFullscreenScreen(true)"
      />
    </transition>
    <transition name="t-fader">
      <loader v-if="isLoader" @ended="isLoader = false" :progress="progress" />
       <!-- -->
    </transition>
    <debugger v-if="$route.query.debug" />
    <transition name="t-fader">
      <app-top-right-button
        :fullScreenEnabled="!$device.isIphone"
        @search="isMenu = true"
        @fullscreen="fullscreen"
        v-if="isBtnMenu && isBtnMenuVisible"
      />
    </transition>
    <transition name="t-translate--up">
      <app-menu @close="isMenu = false" v-show="isMenu" />
    </transition>
    <!-- <template v-if="fullScreenEnabled">
      <div
        v-if="fullScreenElement"
        class="controls-button controls-button--exit-fullscreen"
        @click="fullscreen"
      />
      <div
        v-else
        class="controls-button controls-button--enter-fullscreen"
        @click="fullscreen"
      />
    </template> -->
    <transition :duration="1900" name="transition">
      <transition-screen v-if="transitionScreen" />
    </transition>
  </div>
</template>

<script>
import App from './App'

import { webGL } from '@/webGL/WebGL'

import { Vector3 } from 'three'

import { environment } from '@/webGL/objects/Environment'
import { transition } from '@/Transition'

import SymbolsSvg from '@/components/SymbolsSvg'
import Debugger from '@/components/debugger'
import Loader from '@/components/Loader'
import TransitionScreen from '@/components/common/TransitionScreen'
import AppTopRightButton from '@/components/common/AppTopRightButton'
import FullscreenScreen from '@/components/FullscreenScreen'

import AppMenu from '@/pages/AppMenu'

export default {
  name: 'App',

  mixins: [App],

  components: {
    Debugger,
    SymbolsSvg,
    Loader,
    TransitionScreen,
    AppTopRightButton,
    FullscreenScreen,
    AppMenu
  },

  computed: {
    loaded () {
      return this.$store.getters['data/loaded']
    },
    progress () {
      let res = 0
      res += this.loaded ? .5 : 0
      res += this.isEnvLoaded ? .5 : 0

      return res
    },
    isBtnMenu () {
      return this.$route.name ? !this.$route.name.includes('Introduction') : false
    }
  },

  data () {
    return {
      isReady: false,
      isEnvLoaded: false,
      fullScreenEnabled: false,
      isFullScreen: false,
      transitionScreen: false,
      isMenu: false,
      isBtnMenuVisible: true,
      isFullScreenScreen: true,
      isLoader: true
    }
  },

  created () {
    this.$store.dispatch('data/entries')

    transition.onScreen = value => {
      this.transitionScreen = value
    }
  },

  mounted () {
    // webGL.camera.position.set(15, 10, 15)
    // webGL.camera.look = new Vector3(0, 0, 0)

    // environment.load(this.$device.isIpad || this.$device.isPhone ? 'degrade' : 'ktx').then(() => {
    environment.load('ktx', this.$device.isIpad).then(() => {
      this.isEnvLoaded = true
    })
    webGL.scene.add(environment)

    this.onResize()
  },

  methods: {
    closeFullscreenScreen (isFull = true) {
      this.isFullScreenScreen = false
      if (isFull) {
        this.fullscreen()
      }
    },

    fullscreen () {
      this.onResize()

      if (this.isFullScreen) {
        if (document.exitFullscreen) {
          document.exitFullscreen()
        }
        else if (document.webkitExitFullscreen) {
          document.webkitExitFullscreen()
        }
        this.isFullScreen = false
      }
      else {
        if (document.body.requestFullscreen) {
          document.body.requestFullscreen()
        }
        else if (document.body.webkitRequestFullscreen) {
          document.body.webkitRequestFullscreen()
        }
        this.isFullScreen = true
      }
    },

    onResize () {
      this.fullScreenEnabled = document.fullscreenEnabled
    }
  },

  watch: {
    loaded () {
      if (this.loaded) {
        setTimeout(() => {
          this.isReady = true
        }, 2000)
      }
    },
    $route () {
      this.isBtnMenuVisible = true
    }
  }
}
</script>

<style lang="stylus" src="./styles/theme.styl"/>

<style lang="stylus">
@import '~@/styles/settings/variables'
@import '~@/styles/settings/mixins/mq'

#app
  min-height 100vh
  width 100%

  &.is-full
    position fixed
    top .5px
    bottom .5px
    left 0
    right 0
    overflow hidden
    min-height auto

    +mq($from: 'wide')
      width 1440px
      left calc(50% - 720px)
      right auto

.controls-button
  background-size: contain
  background-position: center center
  width 24px
  height 24px
  bottom $spacings.md
  right $spacings.md
  cursor pointer
  opacity 0.5
  transition .2s
  position absolute
  
  &--enter-fullscreen
    background-image url(/images/enter-fullscreen.svg)

  &--exit-fullscreen
    background-image url(/images/exit-fullscreen.svg)



</style>
