
import '@/mixins/extendedComponent'

import { webGL } from '@/webGL/WebGL'
import { MeshoptDecoder } from 'three/examples/jsm/libs/meshopt_decoder.module.js'
import { engine, LoaderManager, GLTFLoader } from 'shimmer'

import data from '@/assets/data'

export default {

  created () {
    window.addEventListener('resize', this._onResize)
    window.addEventListener('scroll', this._onScroll)
    window.addEventListener('keydown', this._onKeyDown)

    this.$hub.emit('resize', event)
    LoaderManager.config = data.assets

    GLTFLoader.ktx = webGL.renderer.renderer
    // dirty fix
    // GLTFLoader.meshopt = MeshoptDecoder
    GLTFLoader.loader.setMeshoptDecoder(MeshoptDecoder)
  },

  mounted () {
    this.$device._onResize()
    this._onResize()

    webGL.el = this.$refs.containerWebGL
    engine.el = this.$refs.containerWebGL
    engine.camera = webGL.camera
    this.$dom3d.attachTo(this.$refs.containerCSS3D)
    this.$dom3d.setCamera(webGL.camera)

    this.$el.addEventListener(this.$device.pointerdown, engine.pointerdown)
    this.$el.addEventListener(this.$device.pointermove, engine.pointermove)

    // webGL.postProcess = !this.$device.isSafari && !this.$device.isIpad && !this.$device.isIphone
    webGL.postProcess = false

    if (!this.$device.isIphone) {
      webGL.loadBackground()
    }
  },

  methods: {
    onUpdate () {
      this._onUpdate()
    },

    _onUpdate () {
      webGL.update()
      this.$dom3d.update()
    },

    _onResize (event) {
      let width = this.$refs.containerWebGL.offsetWidth
      let height = this.$refs.containerWebGL.offsetHeight

      // shitty fis to prevent renderer from being too wide
      width = width > 1440 ? 1440 : width

      webGL.resize({ width: width, height: height })
      this.$dom3d.onResize(width, height)
    },

    _onScroll (event) {
      this.$device._onScroll()
      this.$hub.emit('scroll', event)
    },

    _onKeyDown (event) {
      this.$hub.emit('keydown', event.keyCode)
      if (event.key === 'Escape' || event.key === 'Esc' || event.keyCode === 27) {
        this.$hub.emit('keydown:escape', event.keyCode)
      }
    }
  }
}
