<template>
  <div class="menu u-wrapper-panel u-row u-bg-green u-overflow-y-scroll u-invisible-scroll">
    <svg @click="$emit('close')" class="menu__close u-fill-yellow u-shadow" >
      <use xlink:href="#close"/>
    </svg>
    <div class="menu__counter t-text u-yellow u-round u-row u-center u-middle">
      {{ totalSeen }}/{{ total }}
    </div>
    <div class="u-left u-w11of12 u-marg-l-1of12">
      <div class="t-h1 u-yellow u-marg-t-md u-marg-t-xl--sm u-pad-t-lg--sm u-marg-b-lg u-pad-r-sm">
        Découvrez notre restaurant
      </div>
      <div v-for="place in places">
        <div class="u-uppercase u-yellow u-marg-b-sm">
          <div class="t-h2 u-inline-block">
            {{place.title}}
          </div>
          <div class="t-text t- u-inline-block menu__countersmall u-radius u-marg-l-sm">
            {{place.keypointsOnly.filter(keypoint => keypoint.isSeen).length}}/{{ place.keypointsOnly.length }}
          </div>
        </div>
        <div class="u-full-width u-overflow-x-scroll u-invisible-scroll u-marg-y-md">
          <div class="menu__wrapper" :style="{width: (180 + 20) * place.keypointsOnly.length + 'px'}">
            <place-card class="menu__item u-marg-r-sm" v-for="keypoint in place.keypointsOnly" :key="keypoint.slug" :data="keypoint" :place="place" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AppButton from '@/components/common/AppButton'
import PlaceCard from '@/components/common/PlaceCard'
import { webGL } from '@/webGL/WebGL'

export default {
  components: {
    AppButton,
    PlaceCard
  },

  computed: {
    places () {
      return this.$store.getters['data/entries']
    },
    total () {
      return this.$store.getters['data/total']
    },
    totalSeen () {
      return this.$store.getters['data/totalSeen']
    }
  },

  mounted () {
    webGL.camera.position.set(-15, 10, 27)
    webGL.camera.look.set(0, 0, 0)
    webGL.camera.forceUpdateLook = true
  },

  watch: {
    $route () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="stylus">
@import '~@/styles/settings/variables'

.menu
  &__close
    position absolute
    top: $spacings.md
    left: $spacings.md
    border-radius 50%
    height 60px
    width 60px
    padding 18px
    
  &__counter
    position absolute
    top: $spacings.md
    right: $spacings.md
    border: solid 1px $colors.yellow
    height 70px
    width 70px
  
  &__countersmall
    padding 4px 15px
    border: solid 2px $colors.yellow

  &__wrapper
    display flex
    flex-wrap nowrap

  &__item
    width 215px
</style>