// https://stackoverflow.com/questions/14614252/how-to-fit-camera-to-object#answer-55976098

import { Box3, Vector3 } from 'three'
import { polarToCartesian } from '@/utils/Maths/polarToCartesian'
import { cartesianToPolar } from '@/utils/Maths/cartesianToPolar'

const PI_HALF = Math.PI * .5

export class FitTo {
  constructor (camera) {
    this.camera = camera

    this.fit = this.fit.bind(this)
  }

  // padding is either an object { top, right, bottom, left }
  // either a single value
  fit (box, padding = 0, {vector, angles}) {
    let objectSize = {width: 0, height: 0}

    let distance = 0

    let center = new Vector3()
    box.getCenter(center)

    if (vector === void 0 && angles === void 0) {
      vector = center.clone().sub(this.camera.position)
    }
    else if (angles) {
      if ( angles.polar === void 0 || angles.azimuth === void 0) {
        console.log('azimuth and polar need to be assigned')
        return
      }
      const pos = polarToCartesian(1, angles.polar, angles.azimuth)
      vector = new Vector3(pos.x, pos.y, pos.z)
    }

    vector.normalize()

    const face = this.getFace(vector)

    let size = new Vector3()
    box.getSize(size)

    if (typeof padding === 'number') {
      padding = {
        top: padding,
        right: padding,
        bottom: padding,
        left: padding
      }
    }

    const newBox = new Box3().makeEmpty()

    // Face or butt -> Z
    if (face === 'FRONT' || face === 'BUTT') {
      // FRONT
      if (face === 'FRONT') {
        let point = new Vector3().copy(center)
        point.x += size.x * .5 + size.x * padding.right
        newBox.expandByPoint(point)

        point = new Vector3().copy(center)
        point.x -= size.x * .5 + size.x * padding.left
        newBox.expandByPoint(point)

        point = new Vector3().copy(center)
        point.y += size.y * .5 + size.y * padding.top
        newBox.expandByPoint(point)

        point = new Vector3().copy(center)
        point.y -= size.y * .5 + size.y * padding.bottom
        newBox.expandByPoint(point)


      }
      else if (face === 'BUTT') {
        let point = new Vector3().copy(center)
        point.x += size.x * .5 + size.x * padding.left
        newBox.expandByPoint(point)

        point = new Vector3().copy(center)
        point.x -= size.x * .5 + size.x * padding.right
        newBox.expandByPoint(point)

        point = new Vector3().copy(center)
        point.y += size.y * .5 + size.y * padding.top
        newBox.expandByPoint(point)

        point = new Vector3().copy(center)
        point.y -= size.y * .5 + size.y * padding.bottom
        newBox.expandByPoint(point)
      }

      newBox.getSize(size)

      objectSize = {width: size.x, height: size.y}
      distance = size.z * .5
    }
    // RIGHT OR LEFT -> X
    else if (face === 'RIGHT' || face === 'LEFT') {
      if (face === 'LEFT') {
        let point = new Vector3().copy(center)
        point.z += size.z * .5 + size.z * padding.right
        newBox.expandByPoint(point)

        point = new Vector3().copy(center)
        point.z -= size.z * .5 + size.z * padding.left
        newBox.expandByPoint(point)

        point = new Vector3().copy(center)
        point.y += size.y * .5 + size.y * padding.top
        newBox.expandByPoint(point)

        point = new Vector3().copy(center)
        point.y -= size.y * .5 + size.y * padding.bottom
        newBox.expandByPoint(point)
      }
      else if (face === 'RIGHT') {
        let point = new Vector3().copy(center)
        point.z += size.z * .5 + size.z * padding.left
        newBox.expandByPoint(point)

        point = new Vector3().copy(center)
        point.z -= size.z * .5 + size.z * padding.right
        newBox.expandByPoint(point)

        point = new Vector3().copy(center)
        point.y += size.y * .5 + size.y * padding.top
        newBox.expandByPoint(point)

        point = new Vector3().copy(center)
        point.y -= size.y * .5 + size.y * padding.bottom
        newBox.expandByPoint(point)
      }

      newBox.getSize(size)

      objectSize = {width: size.z, height: size.y}
      distance = size.x * .5
    }
    // TOP OR BOTTOM -> Y
    else if (face === 'TOP' || face === 'BOTTOM') {
      if (face === 'TOP') {
        let point = new Vector3().copy(center)
        point.z += size.z * .5 + size.z * padding.right
        newBox.expandByPoint(point)

        point = new Vector3().copy(center)
        point.z -= size.z * .5 + size.z * padding.left
        newBox.expandByPoint(point)

        point = new Vector3().copy(center)
        point.x += size.x * .5 + size.x * padding.top
        newBox.expandByPoint(point)

        point = new Vector3().copy(center)
        point.x -= size.x * .5 + size.x * padding.bottom
        newBox.expandByPoint(point)
      }
      else if (face === 'BOTTOM') {
        let point = new Vector3().copy(center)
        point.z += size.z * .5 + size.z * padding.left
        newBox.expandByPoint(point)

        point = new Vector3().copy(center)
        point.z -= size.z * .5 + size.z * padding.right
        newBox.expandByPoint(point)

        point = new Vector3().copy(center)
        point.x += size.x * .5 + size.x * padding.top
        newBox.expandByPoint(point)

        point = new Vector3().copy(center)
        point.x -= size.x * .5 + size.x * padding.bottom
        newBox.expandByPoint(point)
      }

      newBox.getSize(size)

      objectSize = {width: size.z, height: size.x}
      distance = size.y * .5
    }

    if (objectSize.height >= objectSize.width) {
      const fov = this.camera.fov
      distance += (objectSize.height * .5) / Math.tan(Math.PI * fov / 360)
    }
    else {
      const fov = this.camera.fov * this.camera.aspect
      distance += (objectSize.width * .5) / Math.tan(Math.PI * fov / 360)
    }

    newBox.getCenter(center)

    return {
      position: center.clone().add( vector.clone().multiplyScalar( distance ) ),
      look: center
    }
  }

  facing (vector = new Vector3(0, 0, 1), normal = new Vector3(0, 0, 1)) {
    return Math.abs(vector.angleTo( normal )) <= Math.PI * .31 // .31 is based on shitty test by placing the camera in the most faraway angle
  }

  getFace (vector) {
    if (this.facing(vector, new Vector3(0, 0, 1))) {
      return 'FRONT'
    }
    else if (this.facing(vector, new Vector3(0, 0, -1))) {
      return 'BUTT'
    }
    else if (this.facing(vector, new Vector3(1, 0, 0))) {
      return 'RIGHT'
    }
    else if (this.facing(vector, new Vector3(-1, 0, 0))) {
      return 'LEFT'
    }
    else if (this.facing(vector, new Vector3(0, 1, 0))) {
      return 'TOP'
    }
    else if (this.facing(vector, new Vector3(0, -1, 0))) {
      return 'BOTTOM'
    }
  }
}