import { Component } from 'shimmer'

import {
  Vector3,
  Vector2,
  PlaneBufferGeometry,
  MeshBasicMaterial,
  Mesh,
  Raycaster,
  Quaternion
} from 'three'

import { VirtualScroll } from '@/utils/VirtualScroll'

import gsap from 'gsap'

const yAxis = new Vector3(0, 1, 0)
const xAxis = new Vector3(1, 0, 0)

const maxScroll = 1200

const raycaster = new Raycaster()

import Vue from 'vue'

export class CameraWalk extends Component {
  constructor (camera, scene, lvlScroll, isTouch, isScroll = true, isIphone = false) {
    super()

    this.camera = camera
    this.scene = scene

    this.plane = new Mesh(new PlaneBufferGeometry(3000, 3000), new MeshBasicMaterial({
      depthTest: false,
      depthWrite: false,
    }))
    this.plane.material.visible = false
    this.plane.rotation.x = -Math.PI / 2
    this.scene.add(this.plane)
    this.lvlScroll = lvlScroll

    this.onPointerdown = this.onPointerdown.bind(this)
    this.onPointerup = this.onPointerup.bind(this)
    this.onPointermove = this.onPointermove.bind(this)
    this.onScroll = this.onScroll.bind(this)
    this.onMap = this.onMap.bind(this)

    this.currentX = 0
    this.currentY = 0
    this.currentUltraY = 0
    this.delta = {x: 0, y: 0}
    this.scrollProgress = 0
    this.lastScrollProgress = 0
    this.isActive = false
    this.isTouch = isTouch
    this.isScroll = isScroll
    this.isIphone = isIphone

    this.callbackStart = () => {return}
    this.callbackEnd = () => {return}
  }

  set active (isActive) {
    this.isActive = isActive

    if (this.isActive) {
      this.baseCameraY = this.camera.position.y

      this.virtualScroll = new VirtualScroll({ clamp: { min: 0, max: maxScroll } })
      this.virtualScroll.onSmoothScroll(this.onScroll)
      this.virtualScroll.isScrollEnable = !this.isTouch
    }
    else {
      if (this.virtualScroll) {
        this.virtualScroll.destroy()
      }
    }
  }

  get active () {
    return 

  }

  onPointerdown (cursorPosition) {
    this.isPointerdown = true
    this.cursorPosition = cursorPosition

    this.computeLook()
  }

  onPointerup () {
    this.isPointerdown = false
    this.delta = {x: 0, y: 0}

    this.computeLook()

    this.callbackEnd()
  }

  onStartMoving (callback) {
    this.callbackStart = callback
  }

  onEndMoving (callback) {
    this.callbackEnd = callback
  }

  onPointermove (cursorPosition) {
    if (!this.isPointerdown) {return}

    this.callbackStart()

    this.delta = {x: this.cursorPosition.x - cursorPosition.x, y: this.cursorPosition.y - cursorPosition.y}

    if (this.isIphone) {
      this.delta.y = 0
    }

    this.cursorPosition = cursorPosition
  }

  onScroll ({ progress }) {
    if (!this.isScroll) {return}
    this.scrollProgress = progress
  }

  onUpdate () {
    if (!this.isActive) {return}
    const xSmooth = this.isPointerdown ? .3 : .1
    const ySmooth = this.isPointerdown ? .015 : .03
    this.currentX += (this.delta.x - this.currentX) * xSmooth
    this.currentY += (this.delta.y - this.currentY) * .08
    this.currentUltraY += (this.delta.y - this.currentUltraY) * ySmooth

    this.camera.rotateOnWorldAxis(yAxis, -this.currentX * .0015)

    const director = new Vector3()
    director.subVectors(this.camera.position, this.camera.look)
    director.normalize()
    const posFactor = .013

    this.camera.position.x += director.x * this.currentY * posFactor
    this.camera.position.z += director.z * this.currentY * posFactor
    this.camera.position.y = this.baseCameraY - this.currentUltraY * .15

    const scrollY = this.scrollProgress * (this.lvlScroll.max - this.lvlScroll.min)
    this.camera.position.y += scrollY
    this.camera.rotateX(-(this.scrollProgress - this.lastScrollProgress) * .3)

    this.lastScrollProgress = this.scrollProgress

    this.delta = {x: 0, y: 0}
  }

  computeLook () {
    raycaster.setFromCamera(new Vector2(0, 0), this.camera)

    const intersects = raycaster.intersectObject( this.plane, false )

    if (intersects.length) {
      const look = new Vector3(intersects[0].point.x, intersects[0].point.y, intersects[0].point.z)
      this.camera._lookAt.copy(look)
      this.camera._lastLookAt.copy(look)
    }
  }

  onMap () {
    this.virtualScroll.scroll = maxScroll
  }

  set isMap (value) {
    if (value === true) {
      this.virtualScroll.scroll = maxScroll
    }
    else {
      this.virtualScroll.scroll = 0
    }
  }

  get isMap () {
    return this.virtualScroll.scroll > maxScroll * .8
  }

  onDestroy() {
    if (this.virtualScroll) {
      this.virtualScroll.destroy()
    }
    this.destroy()
  }
}