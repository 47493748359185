import { api } from '@/store/interfaces/apiCraft'
import Vue from 'vue'
import { slugifier } from '@/utils/slugifier'

export default {
  namespaced: true,

  state: {
    entries: [],
    keypoints: [],
    loaded: false,
    total: 0
  },

  getters: {
    loaded: state => {
      return state.loaded
    },
    entries: state => {
      return state.entries
    },
    entryBySlug: state => slug => {
      return state.entries.find(entry => entry.slug === slug)
    },
    keypointBySlug: (state, getters) => (entrySlug, keypointSlug) => {
      const entry = getters.entryBySlug(entrySlug)
      return entry.keypoints.find(keypoint => keypoint.slug === keypointSlug)
    },
    keypoints: state => {
      return []
    },
    total: state => {
      return state.total
    },
    totalSeen: state => {
      let total = 0

      state.entries.forEach(entry => {
        entry.keypointsOnly.forEach(keypoint => {
          if (keypoint.isSeen) {
            total++
          }
        })
      })

      return total
    },
  },

  actions: {
    async entries ({ commit, state }) {
      const { request, cache } = api.getEntries()
      cache.then(res => {
        if (res !== null) {
          commit('entries', res)
          commit('loaded', true)
        }
      })

      request.then(res => {
        commit('entries', res)
        commit('loaded', true)
      })
    }
  },

  mutations: {
    loaded (state, payload) {
      state.loaded = payload
    },
    entries (state, entries) {
      let total = 0
      entries.forEach((entry, i) => {
        entry.index = i
        entry.keypoints.forEach((keypoint, j) => {
          if (keypoint.typeHandle === 'keypoint') {
            keypoint.slug = slugifier(keypoint.title)
            keypoint.isSeen = false
          }
          keypoint.index = j
        })
        entry.keypointsOnly = entry.keypoints.filter(keypoint => keypoint.typeHandle === 'keypoint')
        entry.keypointsOnly.forEach((keypoint, index) => {
          keypoint.indexKeypoint = index
          total++
        })
      })
      state.entries = entries
      state.total = total
    },
    isSeen (state, {indexPlace, indexKeypoint}) {
      state.entries[indexPlace].keypoints[indexKeypoint].isSeen = true
    }
  }
}