<template>
  <div class="u-wrapper-panel u-bg-green">
    <img src="/images/m.svg" class="u-block" :class="$device.isMobile ? ' u-fit-contain' :  'u-fit-cover'">
    <div
      class="loader__mask u-wrapper-panel u-bg-green"
      ref="mask"
    />
  </div>
</template>

<script>
import gsap from 'gsap'

export default {
  props: {
    progress: {
      type: Number,
      default: 0
    }
  },

  data () {
    return {
      isLoading: false
    }
  },

  mounted () {
    gsap.to(this.$refs.mask, {
      scaleX: 0,
      duration: 5,
      ease: 'power3.out'
    })
  },

  watch: {
    progress () {
      if (this.progress > .75) {
        gsap.to(this.$refs.mask, {
          scaleX: 0,
          duration: 1,
          ease: 'power3.out',
          complete: () => {
            setTimeout(() => {
              this.$emit('ended')
            }, 500)
          }
        })
      }
    }
  }
}
</script>

<style lang="stylus">
@import '~@/styles/settings/easings'

.loader
  z-index 100
  &__mask
    transform-origin 100% 50%
    transform scaleX(1)
</style>