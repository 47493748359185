import { Vector2, Vector3, Raycaster } from 'three'

export default {
  install (Vue, options) {
    let device = {}

    const raycaster = new Raycaster()

    device.width = 0
    device.height = 0
    device.scroll = { left: 0, top: 0 }
    device.pixelRatio = window.devicePixelRatio

    device.isMobile = false
    device.isTablet = false
    device.isMinHeight = false
    device.isDesktop = true
    device.isTouch = ('ontouchstart' in window) || (navigator.msMaxTouchPoints > 0)
    device.isIpad = !!navigator.userAgent.match(/.*(iPad).*/)
    device.isIphone = !!navigator.userAgent.match(/.*(iPhone).*/)
    device.isAndroid = !!navigator.userAgent.match(/.*(Android).*/)
    device.isFirefox = !!navigator.userAgent.match(/.*((f|F)irefox).*/)
    device.isChrome = !!navigator.userAgent.match(/.*(Chrome).*/)
    device.isSafari = !!navigator.userAgent.match(/.*(Safari).*/) && !device.isChrome
    /* eslint-disable */
    device.isTrident = !!navigator.userAgent.match(/Trident.*rv\:11\./)
    device.isEdge = !!navigator.userAgent.match(/.*(Edge).*/)
    device.isMSIE = !!navigator.userAgent.match(/.*(MSIE ).*/)

    device.pointer = !!window.navigator.pointerEnabled
    device.msPointer = !!window.navigator.msPointerEnabled
    device.pointerdown = device.isTouch ? 'touchstart' : 'pointerdown'
    device.pointerup = device.isTouch ? 'touchend' : 'pointerup'
    device.pointermove = device.isTouch ? 'touchmove' : 'pointermove'
    device.pointerenter = device.isTouch ? 'touchstart' : 'pointerenter'
    device.pointerleave = device.isTouch ? 'touchend' : 'pointerleave'
    device.pointerover = device.isTouch ? 'touchstart' : 'pointerover'
    device.pointerout = device.isTouch ? 'touchend' : 'pointerout'
    device.click = 'click'

    device._onResize = () => {
      device.width = window.innerWidth
      device.height = window.innerHeight
      device.isMobile = device.width <= 576
      device.isTablet = device.width <= 767
      device.isMinHeight = device.height <= 400
      device.isDesktop = !device.isTablet
      device.orientation = window.matchMedia("(orientation: portrait)").matches ? 'portrait' : 'landscape'
      device.pixelRatio = window.devicePixelRatio
    }

    device._onScroll = () => {
      const doc = document.documentElement

      device.scroll.left = (window.pageXOffset || doc.scrollLeft) - (doc.clientLeft || 0)
      device.scroll.top = (window.pageYOffset || doc.scrollTop)  - (doc.clientTop || 0)
    }

    device.getPointerPosition = event => {
      let clientY = event.clientY
      let clientX = event.clientX

      if (event.touches) {
        clientY = event.touches[0].pageY
        clientX = event.touches[0].pageX
      }

      return {
        x: clientX,
        y: clientY
      }
    }

    device.setRaycast = (event, camera, size, offset) => {
      const width = size?.width ? size.width : device.width
      const height = size?.height ? size.height : device.height
      let pos = device.getPointerPosition(event)
      pos.x -= offset?.left ? offset.left : 0
      pos.y -= offset?.top ? offset.top : 0
      pos = new Vector2(( pos.x / width ) * 2 - 1, - ( pos.y / height ) * 2 + 1)
      raycaster.setFromCamera( pos, camera )
    }

    device.getRaycastedPosition = (event, mesh, camera) => {
      device.setRaycast(event, camera)

      return device.getIntersectPosition(mesh)
    }

    device.getIntersectPosition = mesh => {
      let intersects = []
      if (Array.isArray(mesh)) {
        intersects = raycaster.intersectObjects( mesh, true )
      }
      else {
        intersects = raycaster.intersectObject( mesh, true )
      }

      if(intersects.length >= 1) {
        return new Vector3(intersects[0].point.x, intersects[0].point.y, intersects[0].point.z)
      }

      return null
    }

    device.webglAvailable = () => {
      try {
        let canvas = document.createElement("canvas")
        if (!!window.WebGLRenderingContext && (canvas.getContext("webgl") ||  canvas.getContext("experimental-webgl")))
          return true
        else
          return false
      } catch(e) {
        return false
      }
    }

    let component = new Vue({
      data () {return device}
    })

    Vue.prototype.$device = device
  }
}
