<template>
  <router-link @click="$emit('close')" :to="to" class="card u-bg-yellow u-radius u-overflow-hidden" :style="{opacity: data.isSeen ? 1 : .5}">
    <div class="u-box-4by3">
      <app-image class="u-wrapper-panel u-fit-cover u-radius" :id="data.preview.length ? data.preview[0].id : ''" />
    </div>
    <div class="t-text--small t-slab u-green u-center u-pad-t-sm u-pad-b-sm u-pad-x-xs u-relative">
      {{ data.title }}
      <img v-if="data.isSeen" class="card__seen" src="/images/check.png">
    </div>
  </router-link>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      default: {}
    },
    place: {
      type: Object,
      default: {}
    }
  },

  computed: {
    to () {
      return {
        ...this.$local('Keypoint'),
        params: {
          slug: this.place.slug,
          place: this.data.slug
        },
        query: {
          fast: true
        }
      }
    }
  }
}
</script>

<style lang="stylus">
@import '~@/styles/settings/variables'

.card__seen
  position absolute
  top -20px
  left calc(50% - 20px)
  width 40px
  height 40px
  
</style>