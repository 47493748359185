<template>
  <div class="u-wrapper-panel" @click="onClose">
    <transition :name="$device.isMobile ? 't-translate--up' : 't-translate--right'">
      <panel
        :data="data"
        :entry="entry"
        @popin="onPopin"
        v-if="!isTransition"
        @scroll="e => {isPanelOpen = e}"
      />
    </transition>
    <transition name="t-fader">
      <router-link
        v-if="!$device.isTablet && !isTransition"
        :to="prevKeypoint"
        class="keypoint__button keypoint__button--left u-cursor-pointer u-bg-yellow"
      >
        <div class="u-full-width u-full-height">
          <svg class="u-fit-contain u-fill-green" >
            <use xlink:href="#arrow-left"/>
          </svg>
        </div>
      </router-link>
    </transition>
    <transition name="t-fader">
      <router-link
        v-if="!$device.isTablet && !isTransition"
        :to="nextKeypoint"
        class="keypoint__button keypoint__button--right u-cursor-pointer u-bg-yellow"
      >
        <div class="u-full-width u-full-height">
          <svg class="u-fit-contain u-fill-green" >
            <use xlink:href="#arrow-right"/>
          </svg>
        </div>
      </router-link>
    </transition>

    <!-- <transition name="t-fader">
      <popin :media="popinMedia" v-if="popinMedia" @close="popinMedia = ''" />
    </transition> -->
    <transition name="t-fader">
      <app-back-button :to="{...$local('Place'), params: { slug: entry.slug }}" v-if="!isTransition && !isPanelOpen" :name="entry.title" />
    </transition>
  </div>
</template>

<script>
import gsap from 'gsap'

import { webGL } from '@/webGL/WebGL'
import { FitTo } from 'shimmer'

import { Vector3, Box3 } from 'three'

import AppBackButton from '@/components/common/AppBackButton'
import Keypoint from '@/components/Keypoint'
import Panel from '@/components/Panel'
import Popin from '@/components/common/Popin'
import { transition } from '@/Transition'

export default {
  components: {
    Keypoint,
    AppBackButton,
    Panel,
    Popin,
  },

  data () {
    return {
      popinMedia: '',
      isTransition: false,
      isPanelOpen: false
    }
  },

  computed: {
    entry () {
      return this.$store.getters['data/entryBySlug'](this.$route.params.slug)
    },

    data () {
      return this.$store.getters['data/keypointBySlug'](this.$route.params.slug, this.$route.params.place)
    },

    children () {
      return this.data.keypoints
    },

    nextKeypoint () {
      let target = this.entry.keypointsOnly[this.data.indexKeypoint+1]

      if (!target) {
        target = this.entry.keypointsOnly[0]
        return {
          ...this.$local('Keypoint'),
          params: {
            slug: this.entry.slug,
            place: target.slug
          }
        }
      }

      return {
        ...this.$local('Keypoint'),
        params: {
          slug: this.entry.slug,
          place: target.slug
        }
      }
    },

    prevKeypoint () {
      let target = this.entry.keypointsOnly[this.data.indexKeypoint-1]

      if (!target) {
        target = this.entry.keypointsOnly[this.entry.keypointsOnly.length - 1]
        return {
          ...this.$local('Keypoint'),
          params: {
            slug: this.entry.slug,
            place: target.slug
          }
        }
      }

      return {
        ...this.$local('Keypoint'),
        params: {
          slug: this.entry.slug,
          place: target.slug
        }
      }
    }
  },

  mounted () {
    this.onInit()
    this.onResize()
    this.$hub.on('transition', this.onTransition)
  },

  destroyed () {
    this.$dom3d.removeObject(this.object)
    this.$hub.off('transition', this.onTransition)
  },

  methods: {
    onTransition (value) {
      this.isTransition = value
    },

    onInit () {
      this.isPanelOpen = false
      this.$store.commit('data/isSeen', {indexPlace: this.entry.index, indexKeypoint: this.data.index})
    },

    onClose () {
      this.currentIndex = this.currentIndex !== -1 ? -1 : this.currentIndex
    },

    onPopin (media) {
      this.popinMedia = media
    }
  },

  watch: {
    $route () {
      this.onInit()
    },
    isPanelOpen () {
      this.$emit('isSearch', !this.isPanelOpen)
    },
  }
}
</script>

<style lang="stylus">
@import '~@/styles/settings/variables'

.keypoint
  &__button
    position absolute
    top 50%
    left 30px
    height 80px
    width 80px
    padding 23px
    border-radius 50%
    
    &--right
      left auto
      // 450 + 30
      right 480px

</style>