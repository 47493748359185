
export default {
  install (Vue, options) {
    let offset = {}

    offset.absolute = (element) => {
      let bodyRect = document.body.getBoundingClientRect()
      let elemRect = element.getBoundingClientRect()

      return {
        top: elemRect.top - bodyRect.top,
        left: elemRect.left - bodyRect.left,
        bottom: elemRect.bottom - bodyRect.bottom,
        right: elemRect.right - bodyRect.right
      }
    }

    offset.relative = (el) => {
      let _x = 0
      let _y = 0
      while (el && !isNaN(el.offsetLeft) && !isNaN(el.offsetTop)) {
        _x += el.offsetLeft - el.scrollLeft
        _y += el.offsetTop - el.scrollTop
        el = el.offsetParent
      }
      return { top: _y, left: _x }
    }

    Vue.prototype.$offset = offset
  }
}
