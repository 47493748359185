<template>
  <div class="top-right-button">
    <div @click="$emit('search')" class="u-middle u-inline-block">
      <div class="top-right-button__icon u-bg-green u-cursor-pointer u-shadow">
        <div class="u-full-width u-full-height">
          <svg class="u-fit-contain u-fill-yellow" >
            <use xlink:href="#search"/>
          </svg>
        </div>
      </div>
    </div>
    <div v-if="fullScreenEnabled" @click="$emit('fullscreen')" class="u-middle u-inline-block u-marg-l-sm">
      <div class="top-right-button__icon u-bg-green u-cursor-pointer u-shadow">
        <div class="u-full-width u-full-height">
          <svg class="u-fit-contain u-fill-yellow" >
            <use xlink:href="#fullscreen"/>
          </svg>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    fullScreenEnabled: {
      type: Boolean,
      default: true
    }
  }
}
</script>

<style lang="stylus">
@import '~@/styles/settings/variables'
@import '~@/styles/settings/mixins/mq'

.top-right-button
  position absolute
  top: $spacings.md
  right: $spacings.md

  +mq($until: 'tablet')
    top: $spacings.sm !important
    right: $spacings.sm !important
  
  &__icon
    height 60px
    width 60px
    padding 18px
    border-radius 50%
  
    +mq($until: 'tablet')
      height 60px
      width 60px
      padding 18px
</style>