import Apollo from '@/store/interfaces/Apollo'
import gql from 'graphql-tag'
import { storage } from '@/utils/storage'

const apollo = new Apollo(process.env.VUE_APP_CRAFT_API, false)
apollo.setToken('Bearer ' + process.env.VUE_APP_CRAFT_TOKEN)
const graphqlClient = apollo.client

const site = process.env.VUE_APP_CRAFT_WEBSITE

class APICraft {

  getEntries () {
    const args = arguments

    const request = new Promise((resolve, reject) => {
      graphqlClient.query({
        query: gql`
          query fetchEntries {
            entries (site: "${site}") {
              ... on mcdoRestoPlaces_mcdoRestoPlaces_Entry {
                id
                slug
                title
                position {
                  x
                  y
                  z
                }
                image {
                  id 
                  url
                }
                trajectory: mcdoIntroductionTrajectory {
                  ... on mcdoIntroductionTrajectory_point_BlockType {
                    id
                    position {
                      x
                      y
                      z
                    }
                    look {
                      x
                      y
                      z
                    }
                  }
                }
                keypoints: mcdoKeypoints {
                  ... on mcdoKeypoints_keypoint_BlockType {
                    id
                    title: titre
                    position {
                      x
                      y
                      z
                    }
                    look {
                      x
                      y
                      z
                    }
                    zoom
                    typeHandle
                    text
                    media {
                      id
                      extension
                      url
                    }
                    preview {
                      id
                      url
                    }
                  }
                  ... on mcdoKeypoints_transition_BlockType {
                    position {
                      x
                      y
                      z
                    }
                    look {
                      x
                      y
                      z
                    }
                    zoom
                    typeHandle
                  }
                }
              }
            }
          }
        `
      })
      .then(res => {
        const result = res.data.entries

        resolve(result)
        storage.setItem( `fetchEntries`, result)
      })
      .catch(err => {
        console.error('Error catching global params ', err)
      })
    })
    const cache = storage.getItem( `fetchEntries`)

    return {
      request,
      cache
    }
  }

  // getGlobal () {
  //   const args = arguments

  //   const request = new Promise((resolve, reject) => {
  //     graphqlClient.query({
  //       query: gql`
  //         query fetchGlobal {
  //           globalSet (site: "${site}") {
  //             ... on sbsTown_GlobalSet {
  //               name
  //               sbsTutorials {
  //                 mobileText
  //                 text
  //               }
  //               sbsDistricts {
  //                 ... on sbsdistricts_sbsdistricts_Entry {
  //                   id
  //                   slug
  //                   title
  //                   visible
  //                   sbsKeypoints {
  //                     ... on sbsKeypoints_keypoints_BlockType {
  //                       titre
  //                       positionX
  //                       positionY
  //                       positionZ
  //                       id
  //                       entries {
  //                         ${medias}
  //                       }
  //                     }
  //                   }
  //                 }
  //               }
  //             }
  //           }
  //         }
  //       `
  //     })
  //     .then(res => {
  //       const result = res.data.globalSet

  //       resolve(result)
  //       storage.setItem( `fetchGlobal_${JSON.stringify(args)}`, result)
  //     })
  //     .catch(err => {
  //       console.error('Error catching global params ', err)
  //     })
  //   })
  //   const cache = storage.getItem( `fetchGlobal_${JSON.stringify(args)}`)

  //   return {
  //     request,
  //     cache
  //   }
  // }

  imgSize ({id, width, height}) {
    const args = arguments

    const request = new Promise((resolve, reject) => {
      let query = {}

      if (width && height) {
        query = {
          query: gql`
            query fetchImg ($id: [QueryArgument], $width: Int, $height: Int) {
              asset(id: $id) {
                ... on default_Asset {
                  url(width: $width, immediately: true, height: $height)
                }
              }
            }
          `,
          variables: { id, width, height }
        }
      }
      else if (width && !height) {
        query = {
          query: gql`
            query fetchImg ($id: [QueryArgument], $width: Int) {
              asset(id: $id) {
                ... on default_Asset {
                  url(width: $width, immediately: true)
                }
              }
            }
          `,
          variables: { id, width }
        }
      }
      else if (!width && height) {
        query = {
          query: gql`
            query fetchImg ($id: [QueryArgument], $height: Int) {
              asset(id: $id) {
                ... on default_Asset {
                  url(height: $height, immediately: true)
                }
              }
            }
          `,
          variables: { id, height }
        }
      }
      else {
        query = {
          query: gql`
            query fetchImg ($id: [QueryArgument])  {
              asset(id: $id) {
                ... on default_Asset {
                  url
                }
              }
            }
          `,
          variables: { id }
        }
      }

      graphqlClient.query(query)
        .then(res => {
          resolve(res.data.asset.url)

          const result = res.data.asset.url

          resolve(result)
          storage.setItem( `fetchImg_${JSON.stringify(args)}`, result)
        })
        .catch(err => {
          console.error('Error catching global params ', err)
        })
    })

    const cache = storage.getItem(`fetchImg_${JSON.stringify(args)}`)

    return {
      request,
      cache
    }
  }
}

export const api = new APICraft()