class CopyPaste {
  makeCopy ({ values = false }) {
    if (!values === false) {
      console.warn('CopyPaste.makeCopy needs values')
      return
    }
    console.log('warn', values)
    navigator.permissions.query({ name: 'clipboard-write' }).then(result => {
      console.log('should work?')
      console.log('clipboard-write', result)
      if (result.state === 'granted' || result.state === 'prompt') {
        navigator.clipboard.writeText(values).then(() => {
          console.log('Copie : ok')
        })
          .catch(error => {
            console.error('Copie : erreur : ', error)
          })
      }
    }).catch(err => {
      console.error('Seems like we cannot copy on this navigator ', err)
    })
  }

  onPaste ({ el = document.body, callback = () => {}, stopPropagation = false }) {
    el.addEventListener('paste', (event) => {
      if (stopPropagation) {
        event.stopPropagation()
        event.preventDefault()
      }

      // Get pasted data via clipboard API
      const clipboardData = event.clipboardData || window.clipboardData
      callback(clipboardData.getData('Text'))
    })
  }
}

export default new CopyPaste()
