import { Vector2 } from 'three'

export const SmoothMouse = {
  created () {
    this._smooth = {
      currentPos: new Vector2(),
      lastPos: new Vector2(),
      delta: new Vector2(),
      pos: new Vector2()
    }
  },

  mounted () {
    this.$el.addEventListener('pointermove', this._onPointerMove)
  },

  beforeDestroy () {
    this.$el.removeEventListener('pointermove', this._onPointerMove)
  },

  methods: {
    _onUpdate () {
      this._smooth.currentPos.x += (this._smooth.pos.x - this._smooth.currentPos.x) * .02
      this._smooth.currentPos.y += (this._smooth.pos.y - this._smooth.currentPos.y) * .02

      this._smooth.delta.subVectors(this._smooth.currentPos, this._smooth.lastPos)

      this._smooth.lastPos.copy(this._smooth.currentPos)
    },
    _onPointerMove (event) {
      let pos = this.$device.getPointerPosition(event)
      this._smooth.pos.copy(new Vector2(( pos.x / this.$device.width ) * 2 - 1, - ( pos.y / this.$device.height ) * 2 + 1))
    }
  }
}
